<template>
  <div>
    <v-btn fab small text class="primary" @click="changePasswordDialog = true">
      <v-icon>fas fa-lock</v-icon>
    </v-btn>
    <v-dialog v-model="changePasswordDialog" max-width="400">
      <v-card>
        <v-card-title>
          تغییر کلمه عبور
        </v-card-title>
        <v-card-text>
          <v-form ref="changePasswordForm">
            <v-text-field
                v-model="password"
                :rules="requiredRules"
                outlined label="کلمه عبور"></v-text-field>
            <v-text-field
                v-model="password_confirmation"
                :rules="passwordConfirmRules"
                outlined label="تکرار کلمه عبور"></v-text-field>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" @click="changePasswordDialog = false">لغو</v-btn>
          <v-btn class="primary" @click="changePassword">تایید</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>

</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  // props: ['userId'],
  props:{
    userId:{
      default:null
    },
    changePasswordAction:{
      default:'adminchangeClientPassword'
    }
  },
  data() {
    return {
      password_confirmation: '',
      password: '',
      changePasswordDialog: false
    }
  },
  computed: {
    passwordConfirmRules() {
      return [
        v => !!v || 'پر کردن فیلد تکرار کلمه عبور الزامیست',
        v => (v === this.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
      ];
    },
    requiredRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired'),
        v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
      ];
    },
  },
  methods: {
    changePassword() {
      if (this.$refs.changePasswordForm.validate()) {

        let payload = {
          password: this.password,
          password_confirmation: this.password_confirmation
        };

        EventBus.$emit('setLoading', true);
        this.$store.dispatch(this.changePasswordAction, {id: this.userId, payload}).then((resp) => {
          console.log(resp);
          EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.changePassword'))
          this.changePasswordDialog = false;
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        });

      }
    },
  }
}
</script>

<style scoped>

</style>